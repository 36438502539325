import { Controller } from '@hotwired/stimulus';
import { iframeResize } from 'iframe-resizer'

export default class extends Controller {
    static targets = ['popup', 'content'];
    static classes = ['visible'];
    static values = {
        url: String,
        locale: String,
        mode: Number,
        character: Number,
    };

    connect () {
        this.url = new URL(this.urlValue);

        if (this.hasLocaleValue) {
            this.url.searchParams.set('l', this.localeValue);
        }

        if (this.hasModeValue) {
            this.url.searchParams.set('m', this.modeValue);
        }

        if (this.hasCharacterValue) {
            this.url.searchParams.set('c', this.characterValue);
        }

        this.iframe = document.createElement('iframe');
        this.iframe.src = this.url.toString();
        this.iframe.addEventListener('load', () => {
            iframeResize({}, this.iframe);
        })
    }

    disconnect () {

    }

    show () {
        document.body.style.overflow = 'hidden';
        this.element.classList.add(this.visibleClass);
        this.contentTarget.append(this.iframe);
    }

    hide () {
        document.body.style.overflow = '';
        this.element.classList.remove(this.visibleClass);
        this.iframe.remove();
    }

    ignore () {}
}
