import { Controller } from '@hotwired/stimulus';
import gsap from '../scripts/gsap';

export default class extends Controller {
    static targets = ['level2'];

    initialize () {
        const media = window.matchMedia('(min-width: 768px)')
        this.$desktop = media.matches;
        media.addEventListener('change', (event) => {
            this.$desktop = event.matches;
            this.close(true);
        });
    }

    connect () {
        this.isOpen = false;

        gsap.set(this.level2Target, {
            display: 'block',
            overflow: 'hidden',
            height: 0,
            opacity: 0,
        });
    }

    toggle () {
        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    open () {
        this.dispatch('hide');

        gsap.to(this.level2Target, {
            height: this.level2Target.firstElementChild.getBoundingClientRect().height,
            opacity: 1,
            ease: 'power2.inOut',
            duration: 0.5,
        });

        this.isOpen = true;
    }

    close (instant = false) {
        if (!this.isOpen) {
            return;
        }

        this.dispatch('show');

        gsap.to(this.level2Target, {
            height: 0,
            opacity: 0,
            ease: 'power2.inOut',
            duration: instant === true ? 0 : 0.5,
        });

        this.isOpen = false;
    }

    show (event) {
        if (this.element === event.srcElement) {
            return;
        }

        gsap.to(this.element, {
            height: 'auto',
            opacity: 1,
            ease: 'power2.inOut',
            duration: 0.5,
        });

        this.close();
    }

    hide (event) {
        if (this.element === event.srcElement) {
            return;
        }

        if (this.$desktop) {
            this.close();
        } else {
            gsap.to(this.element, {
                height: 0,
                opacity: 0,
                ease: 'power2.inOut',
                duration: 0.5,
            });
        }
    }
}
