import { Controller } from '@hotwired/stimulus';
import gsap from '../scripts/gsap';

export default class extends Controller {
    static targets = ['inner'];
    static values = {
        text: {
            type: String,
            required: true,
        },
        animation: {
            type: String,
            required: true,
        },
    };

    connect () {
        this.element.classList.add('swirlytext');
        this.element.classList.add(`swirlytext--${this.animationValue}`);

        const inner = document.createElement('div');
        inner.classList.add('swirlytext__inner');
        this.element.append(inner);

        this.styles = window.getComputedStyle(inner);
        const lines = Math.floor(inner.getBoundingClientRect().height / parseInt(this.styles.lineHeight));
        inner.innerHTML = `<div>${this.fillVertical(this.textValue.split("\n"), lines)}</div>`;
        let animation;

        const repeat = Math.floor(inner.getBoundingClientRect().width / inner.firstElementChild.getBoundingClientRect().width);
        for (let r = 1; r < repeat; r++) {
            inner.append(inner.firstElementChild.cloneNode(true));
        }

        switch (this.animationValue) {
            case 'alcohol':
                animation = this.alcohol(repeat);
                break;

            case 'tabac':
                animation = this.tabac(repeat);
                break;
        }

        gsap.timeline()
            .to(this.element, {
                height: '100vh',
                duration: .8,
                ease: 'power4.inOut',
            })
            .call(() => {
                this.dispatch('full')
            })
            .set([this.element, inner], {
                top: 0,
                bottom: 'auto',
            })
            .to(this.element, {
                height: 0,
                duration: .8,
                delay: 1.5,
                ease: 'power4.inOut',
            })
            .call(() => {
                clearInterval(animation);
                this.element.remove();
            })
        ;
    }

    fillVertical (texts, lines) {
        texts = Array(lines).fill('').map((value, index) => texts[index % texts.length]);
        texts = texts.map(word => `<div>${word.split('').map(letter => `<span>${letter}</span>`).join('')}</div>`).join('')

        return texts;
    }

    alcohol (repeat = 1) {
        const letters = [...this.element.querySelectorAll('span')];

        gsap.set(letters, {
            textShadow: `0 0 2px ${this.styles.color}`,
            color: 'transparent',
        })

        const animate = () => {
            const pos = (Math.floor(Math.random()*letters.length));
            const letter = letters[pos];
            delete letters[pos]

            gsap.to(letter, {
                textShadow: `0 0 ${Math.floor(Math.random()*15)}px ${this.styles.color}`,
                color: 'transparent',
                duration: .5
            })
        }

        return setInterval(animate, 25 / repeat);
    }

    tabac (repeat = 1) {
        const letters = [...this.element.querySelectorAll('span')];

        const animate = () => {
            const pos = Math.floor(Math.random()*letters.length);
            const letter = letters[pos];
            delete letters[pos];

            gsap.to(letter, {
                opacity: 0,
                filter: `blur(${Math.floor(Math.random()*10)+20}px)`,
                x: Math.floor(Math.random()*100)+200,
                y: Math.floor(Math.random()*-100)-200,
                duration: Math.random()*2+1
            });
        }

        return setInterval(animate, 100 / repeat);
    }
}
