import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['option'];

    submit (event) {
        event.preventDefault();

        const values = this.optionTargets.filter(el => el.checked).map(el => el.value);

        document.location = `${this.element.action}#${values.join(',')}`
    }
}
