import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static classes = ['hidden'];
    static values = {
        session: String,
        version: {
            type: String,
            default: '1'
        }
    }

    connect () {
        if (!this.hasSessionValue || window.sessionStorage.getItem(this.sessionValue) !== this.versionValue) {
            this.element.classList.remove(this.hiddenClass);
        } else {
            setTimeout(() => {
                this.dispatch('hide');
            }, 300);
        }
    }

    hide () {
        this.element.classList.add(this.hiddenClass);
        this.dispatch('hide');

        if (this.hasSessionValue) {
            window.sessionStorage.setItem(this.sessionValue, this.versionValue)
        }
    }

    ignore () {}
}
