import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    toggle () {
        if (document.body.classList.contains('has-overlay')) {
            document.body.classList.remove('has-overlay');
            this.dispatch('close');
        } else {
            document.body.classList.add('has-overlay');
        }
    }
}
