import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static classes = ['active', 'inactive']

    connect () {
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);

        if (this.hasInactiveClass) {
            this.element.classList.add(this.inactiveClass);
        }

        this.scrollParent = this.getScrollParent(this.element);
        this.scrollParent.addEventListener("scroll", this.add);
        this.scrollParent.addEventListener("scrollend", this.remove);
    }

    disconnect () {
        this.scrollParent.removeEventListener("scroll", this.add);
        this.scrollParent.removeEventListener("scrollend", this.remove);
    }

    add () {
        this.toggle(this.hasActiveClass ? this.activeClass : '', this.hasInactiveClass ? this.inactiveClass : '');
    }

    remove () {
        this.toggle(this.hasInactiveClass ? this.inactiveClass : '', this.hasActiveClass ? this.activeClass : '');
    }

    toggle (add, remove) {
        if (add) {
            this.element.classList.add(add);
        }
        if (remove) {
            this.element.classList.remove(remove);
        }
    }

    getScrollParent (element, includeHidden) {
        let style = getComputedStyle(element);
        const excludeStaticParent = style.position === "absolute";
        const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

        if (style.position === "fixed") return document.body;
        for (var parent = element; (parent = parent.parentElement);) {
            style = getComputedStyle(parent);
            if (excludeStaticParent && style.position === "static") {
                continue;
            }
            if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
        }

        return document.body;
    }
}
