import { Controller } from '@hotwired/stimulus';
import Handorgel from 'handorgel';
import 'handorgel/lib/css/handorgel.min.css';

export default class extends Controller {
    initialize () {
        this.gotoHash = this.gotoHash.bind(this);
    }

    connect () {
        this.$headerElements = this.element.querySelectorAll('.handorgel__header');
        const contentElements = this.element.querySelectorAll('.handorgel__content');

        if (!this.$headerElements.length) {
            return;
        }

        const info = window.location.hash.substring(0, 3) === '#go' ? [window.location.hash.substring(3)] : window.location.hash.substring(1).split(',')

        this.$headerElements.forEach((toggler, index) => {
            if (info.includes(toggler.dataset.elementId)) {
                toggler.dataset.open = 'true';
            } else {
                delete toggler.dataset.open;
                this.element.append(toggler, contentElements[index]);
            }
        });

        this.element.classList.add('handorgel');
        this.$handorgel = new Handorgel(this.element, {
            headerElements: this.$headerElements,
            contentElements,
            multiSelectable: true,
            initialOpenTransition: false,
        });

        this.$handorgel.on('fold:opened', (fold) => {
            this.scrollIntoView(fold.header);
        })

        window.addEventListener('hashchange', this.gotoHash);
        setTimeout(() => this.gotoHash(), 300);
    }

    disconnect () {
        if (this.$handorgel) {
            this.$handorgel.destroy();
            this.$handorgel = null;
        }

        window.removeEventListener('hashchange', this.gotoHash);
    }

    gotoHash () {
        if (window.location.hash.substring(0, 3) !== '#go') {
            return;
        }

        const id = window.location.hash.substring(3);
        let target;

        this.$headerElements.forEach((toggler, index) => {
            if (Number(id) === Number(toggler.dataset.elementId)) {
                target = this.$handorgel.folds[index];
                target.open();
            } else {
                this.$handorgel.folds[index].close()
            }
        });

        if (target) {
            this.scrollIntoView(target.button, true);
        }
    }

    scrollIntoView (el, force = false) {
        const rect = el.getBoundingClientRect();

        if (
            !force &&
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        ) {
            return;
        }

        const offset = parseInt(window.getComputedStyle(document.getElementById('wrapper')).paddingTop);

        window.scrollTo({
            top: rect.top + window.scrollY - offset - 20,
            behavior: 'smooth'
        });
    }
}
