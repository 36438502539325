import { Controller } from '@hotwired/stimulus';
import gsap, { CustomEase } from '../scripts/gsap';

export default class extends Controller {
    static values = {
        type: String,
    };

    connect () {
        this[this.typeValue]();
        this.element.style.visibility = '';
    }

    alcohol () {
        CustomEase.create('alcohol-label', "M0,0 C0.26,1 0.48,1 1,1");
        CustomEase.create('alcohol-scale', "M0,100C6.5,200,12,100,25,100");
        CustomEase.create('alcohol-rotate', "M0,0 C0.42,1.32 0.4,1.09 1,1");

        gsap.timeline({ defaults: { duration: 1 }})
            .fromTo(this.element.querySelector('.bottle_label'), { drawSVG: 0, duration: 0.92, ease: 'alcohol-label' }, { drawSVG: 100 })
            .fromTo(this.element, { scale: 0, ease: 'alcohol-scale' }, { scale: 1 }, 0)
            .fromTo(this.element, { rotate: -40, ease: 'alcohol-rotate' }, { rotate: 0 }, 0)
        ;
    }

    cannabis () {
        CustomEase.create('cannabis-trunk', "M0,0 C0.4,0 0.68,0.06 1,1 ");
        CustomEase.create('cannabis-svg', "M0,0 C0.4,2.4 0.74,1 1,1 ");
        CustomEase.create('cannabis-rotate', "M0,0 C0.4,0.8 0.74,1 1,1");

        gsap.timeline({ defaults: { duration: 1.08 }})
            .set(this.element.children, { visibility: 'hidden' })
            .from(this.element.querySelector('.trunk'), { visibility: 'visible', ease: 'cannabis-trunk', duration: 0.36, drawSVG: 0 })
            .fromTo(this.element.querySelector('.leaf_top_center'), { drawSVG: '50% 50%' }, { visibility: 'visible', drawSVG: '100%', ease: 'cannabis-svg' }, 0.36)
            .fromTo(this.element.querySelectorAll('.leaf_top_left, .leaf_top_right'), { drawSVG: '50% 50%' }, { visibility: 'visible', drawSVG: '100%', ease: 'cannabis-svg' }, 0.36)
            .from(this.element.querySelectorAll('.leaf_top_left'), { transformOrigin: 'bottom right', rotate: '25deg', ease: 'cannabis-rotate', duration: 0.56 }, 0.36)
            .from(this.element.querySelectorAll('.leaf_top_right'), { transformOrigin: 'bottom left', rotate: '-25deg', ease: 'cannabis-rotate', duration: 0.56 }, 0.36)
            .fromTo(this.element.querySelectorAll('.leaf_middle_left, .leaf_middle_right'), { drawSVG: '50% 50%' }, { visibility: 'visible', drawSVG: '100%', ease: 'cannabis-svg' }, 0.56)
            .from(this.element.querySelectorAll('.leaf_middle_left'), { transformOrigin: 'bottom right', rotate: '25deg', ease: 'cannabis-rotate', duration: 0.56 }, 0.56)
            .from(this.element.querySelectorAll('.leaf_middle_right'), { transformOrigin: 'bottom left', rotate: '-25deg', ease: 'cannabis-rotate', duration: 0.56 }, 0.56)
            .fromTo(this.element.querySelectorAll('.leaf_bottom_left, .leaf_bottom_right'), { drawSVG: '50% 50%' }, { visibility: 'visible', drawSVG: '100%', ease: 'cannabis-svg' }, 0.64)
            .from(this.element.querySelectorAll('.leaf_bottom_left'), { transformOrigin: 'bottom right', rotate: '25deg', ease: 'cannabis-rotate', duration: 0.56 }, 0.64)
            .from(this.element.querySelectorAll('.leaf_bottom_right'), { transformOrigin: 'bottom left', rotate: '-25deg', ease: 'cannabis-rotate', duration: 0.56 }, 0.64)
        ;
    }

    tabac () {
        CustomEase.create('tabac-scale', "M0,0 C0.26,1 0.48,1 1,1");
        CustomEase.create('tabac-rotate', "M0,0 C0.42,1.32 0.4,1.09 1,1");

        gsap.timeline({ defaults: { transformOrigin: 'center', duration: 1 }})
            .from(this.element.querySelector('.cigarette'), { ease: 'tabac-scale', scale: 0 }, 0)
            .from(this.element.querySelector('.cigarette'), { ease: 'tabac-rotate', rotate: 27 }, 0)
            .from(this.element.querySelector('.ecigarette'), { ease: 'tabac-scale', scale: 0 }, 0.16)
            .from(this.element.querySelector('.ecigarette'), { ease: 'tabac-rotate', rotate: 27 }, 0.16)
            .from(this.element.querySelector('.flame'), { opacity: 0, duration: 1.04 }, 0.52)
            // .fromTo(this.element.querySelector('.flame'), { opacity: .5 }, { opacity: 1, duration: 1, yoyo: true, repeat: -1 }, 0.13)
            // .fromTo(this.element.querySelector('.flame'), { transformOrigin: 'bottom center', rotate: -5 }, { rotate: 5, duration: 1, yoyo: true, repeat: -1 }, 0.13)
        ;
    }

    medicaments () {
        CustomEase.create('medicaments-body', "M0,0 C0.66,0 0.34,1 1,1");
        CustomEase.create('medicaments-head-scale', "M0,0 C0.32,0.94 0.58,1.18 1,1");
        CustomEase.create('medicaments-head-rotate', "M0,0 C0.32,0.39019 0.58,1.07472 1,1");
        CustomEase.create('medicaments-pill-rotate', "M0,0 C0.26,1 0.48,1 1,1");
        CustomEase.create('medicaments-pill1-position', "M32,-301C37.46,-950,42.08,-950,53,-950");
        CustomEase.create('medicaments-pill2-position', "M32,-301C37.46,-950,42.08,-950,53,-950");

        window.medi = gsap.timeline()
            .set(this.element.querySelectorAll('.pill_1, .pill_2'), { transformOrigin: 'center', x: -97.4, y: -9.2, visibility: 'hidden' }, 0)

            .from(this.element.querySelectorAll('.bottle_body'), { drawSVG: 0, duration: 1.3, ease: 'medicaments-body' }, 0)

            .from(this.element.querySelector('.bottle_head'), { scale: 0, duration: 0.6, ease: 'medicaments-head-scale' }, 0.92)
            .from(this.element.querySelector('.bottle_head'), { rotate: '-53deg', duration: 0.6, ease: 'medicaments-head-rotate' }, 0.92)
            .from(this.element.querySelector('.bottle_head'), { y: -372.3, duration: 1.32 }, 1.16)

            .to(this.element.querySelector('.pill_1'), { visibility: 'visible', motionPath: { path: "M -97.4 -9.2 C -77.7 -64.7 -57.3 -109.6 -32.5 -144.0 C 116.6 -350.2 322.6 -180.5 102.6 338.8", fromCurrent: false }, duration: 1.2, ease: 'medicaments-pill1-position' }, 1)
            .from(this.element.querySelector('.pill_1'), { scale: 4.61, duration: 1, ease: 'medicaments-pill-rotate' }, 1)
            .fromTo(this.element.querySelector('.pill_1'), { rotate: '-204deg' }, { rotate: 0, duration: 1.2, ease: 'medicaments-pill-rotate' }, 1)

            .to(this.element.querySelector('.pill_2'), { visibility: 'visible', motionPath: { path: "M -127.6 19.1 C 61.7 -439.2 464.1 -76.5 246.4 257.1", fromCurrent: false }, duration: 0.8, ease: 'medicaments-pill2-position' }, 1.3)
            .from(this.element.querySelector('.pill_2'), { scale: 3.01, duration: 0.8, ease: 'medicaments-pill-rotate' }, 1.3)
            .fromTo(this.element.querySelector('.pill_2'), { rotate: '-180deg' }, { rotate: 0, duration: 0.95, ease: 'medicaments-pill-rotate' }, 1.3)
        ;
    }
}
