import { Controller } from '@hotwired/stimulus';
import gsap, { CustomEase } from '../scripts/gsap';

let resizeT;

export default class extends Controller {
    static targets = ['serpenti', 'explosion', 'moon', 'galaxy'];

    initialize () {
        CustomEase.create('perspective', 'M0,336.1135C81.84,336.1135,42.16,88.1757,124,88.1757C206.5,88.1757,166.5,336.1135,249,336.1135');

        this.media = window.matchMedia('(min-width: 768px)');
        this.resize = this.resize.bind(this);
        this.reset = this.reset.bind(this);
    }

    connect () {
        window.addEventListener('resize', this.resize)
        this.resize();
    }

    disconnect () {
        clearTimeout(resizeT);
        window.removeEventListener('resize', this.resize);
    }

    resize () {
        clearTimeout(resizeT);
        resizeT = setTimeout(() => this.reset(), 100);
    }

    reset () {
        if (this.$desktop === this.media.matches) {
            return;
        }

        this.$desktop = this.media.matches;

        this.$perspective?.revert();
        this.$perspective?.clear();

        if (this.$desktop) {
            return;
        }

        this.$perspective = gsap.timeline({ repeat: -1, defaults: { ease: 'perspective', duration: 10 } });

        // bottom left
        if (this.hasSerpentiTarget) {
            this.$perspective
                .fromTo(this.serpentiTarget, {
                    transformOrigin: '0 0',
                    position: 'absolute',
                    left: '-9%',
                    top: '55%',
                }, {
                    left: '-10%',
                    top: '52%',
                    duration: 10,
                }, 0)
                .fromTo(this.serpentiTarget.firstElementChild, {
                    transformOrigin: '100% 0',
                    scaleY: 1.1,
                }, {
                    scaleY: 0.9,
                    duration: 10,
                }, 0)
            ;
        }

        // top left
        if (this.hasExplosionTarget) {
            this.$perspective
                .fromTo(this.explosionTarget, {
                    transformOrigin: '0 0',
                    rotateZ: '20.7deg',
                    position: 'absolute',
                    scale: 0.731,
                    left: '2%',
                    top: '5%',
                }, {
                    left: '4%',
                    top: '4%',
                }, 0)
                .fromTo(this.explosionTarget.firstElementChild, {
                    transformOrigin: '50% 50%',
                    scaleY: 1,
                }, {
                    transformOrigin: '100% 100%',
                    scaleY: 1.2,

                }, 0)
            ;
        }

        // bottom right
        if (this.hasMoonTarget) {
            this.$perspective
                .fromTo(this.moonTarget, {
                    transformOrigin: '0 0',
                    position: 'absolute',
                    left: '50%',
                    bottom: '-3%',
                    scale: 0.8,
                }, {
                    left: '55%',
                    bottom: '-1%',
                    scale: 0.731,
                })
            ;
        }

        // top right
        if (this.hasGalaxyTarget) {
            this.$perspective
                .fromTo(this.galaxyTarget, {
                    transformOrigin: '0 0',
                    position: 'absolute',
                    left: '80%',
                    top: '37%',
                    scale: 1.5,
                }, {
                    left: '83%',
                    top: '35%',
                })
                .fromTo(this.galaxyTarget.firstElementChild, {
                    transformOrigin: '50% 50%',
                    scaleY: 1.1,
                }, {
                    transformOrigin: '100% 100%',
                    scaleY: 1.4,
                }, 0)
            ;
        }
    }

    serpentiTargetConnected () {
        this.reset();
    }

    explosionTargetConnected () {
        this.reset();
    }

    moonTargetConnected () {
        this.reset();
    }

    galaxyTargetConnected () {
        this.reset();
    }
}
