import { Controller } from '@hotwired/stimulus';
import gsap from '../scripts/gsap';

let resizeT;

export default class extends Controller {
    static targets = ['navigation', 'container', 'level1'];

    initialize () {
        this.home = this.home.bind(this);
        this.resize = this.resize.bind(this);
        this.media = window.matchMedia('(min-width: 768px)');
    }

    connect () {
        this.illuHome = document.querySelectorAll('.illu__item--star8pink, .illu__item--potato, .illu__item--donut');
        this.illuFilter = document.querySelector('.illu__item--star6');

        document.querySelector('.section-header__logo').addEventListener('click', this.home);
        window.addEventListener('resize', this.resize)
        this.reset();
    }

    disconnect () {
        clearTimeout(resizeT);
        document.querySelector('.section-header__logo').removeEventListener('click', this.home);
        window.removeEventListener('resize', this.resize);
    }

    resize () {
        clearTimeout(resizeT);
        resizeT = setTimeout(() => this.reset(true), 50);
    }

    reset (resize = false) {
        if (resize && this.$desktop === this.media.matches) {
            this.resizeSlider(0);
            return;
        }

        this.$desktop = this.media.matches;
        this.$filter = null;
        gsap.set([...this.element.children].slice(1), { display: 'none' });

        if (this.items) {
            for (let i = 0; i < this.items.length; i++) {
                gsap.set(this.items[i], {
                    x: 0,
                    y: 0,
                    opacity: 1,
                });
            }
        }

        this.items = [[...document.querySelectorAll('#nav0 li > *')]];

        gsap.set(document.querySelector('#nav0'), { clearProps: 'height' });
        gsap.set(this.navigationTarget, {
            height: document.querySelector('#nav0').getBoundingClientRect().height,
        })

        if (this.$desktop) {
            gsap.set(this.items[0], {
                x: 0,
                y: '-100vh',
            })
        } else {
            gsap.set(this.items[0], {
                x: '-100vw',
                y: 0,
            })
        }

        this.resizeSlider(0);

        if (
            window.location.hash.startsWith('#!')
            && document.querySelectorAll(`#${window.location.hash.substring(2)}`).length
        ) {
            this.goto(window.location.hash.substring(2))
        }
    }

    home (event) {
        event.preventDefault();

        this.back();

        if (this.items.length < 2) {
            return;
        }

        // Make sure the previous timeline is finished
        this.timeline?.progress(1);

        if (this.$desktop) {
            this.timeline = gsap.timeline()
                .to(this.items[this.items.length - 1], {
                    y: 0,
                    opacity: 0,
                    duration: 0.3,
                    stagger: 0.1,
                    ease: "back.in(1.7)",
                })
                .to(this.items[0], {
                    y: '-100vh',
                    opacity: 1,
                    duration: 0.2,
                    stagger: 0.1,
                    ease: "back.out(1.7)",
                })
                .set(this.items.slice(1, this.items.length - 1), {
                    y: 0,
                })
                .to(this.navigationTarget, {
                    height: document.querySelector('#nav0').getBoundingClientRect().height,
                    duration: 0.3,
                })
                .call(() => this.resizeSlider(0.3), 0)
                .call(() => {
                    this.items = [this.items[0]];
                });
        } else {
            this.timeline = gsap.timeline()
                .to(this.items[this.items.length - 1], {
                    x: 0,
                    duration: 0.2,
                    stagger: 0.1,
                    ease: "back.in(1.7)",
                })
                .to(this.items[0], {
                    x: '-100vw',
                    duration: 0.3,
                    stagger: 0.1,
                    ease: "back.out(1.7)",
                })
                .set(this.items.slice(1, this.items.length - 1), {
                    x: 0,
                })
                .to(this.navigationTarget, {
                    height: document.querySelector('#nav0').getBoundingClientRect().height,
                    duration: 0.3,
                })
                .call(() => this.resizeSlider(0.3), 0)
                .call(() => {
                    this.items = [this.items[0]];
                })
            ;
        }
    }

    back () {
        const height = this.element.children[0].getBoundingClientRect().height;

        gsap.timeline({ duration: 0.6 })
            .to(this.element, { y: 0, height, ease: 'power2.inOut' }, 0)
            .to(this.illuHome, { opacity: 1 }, 0)
            .to(this.illuFilter, { opacity: 0 }, 0)
            .set([...this.element.children].slice(1), { display: 'none' })
        ;

        this.$filter = null;
    }

    intro () {
        gsap.to(this.items[0], {
            keyframes: {
                scale: [1.0, 1.08, 1.0],
            },
            duration: 0.8,
            stagger: 0.2,
            ease: 'bounce.out'
        });
    }

    goto (target) {
        if (target instanceof Event) {
            target = target.params.target;
        }

        // Make sure the previous timeline is finished
        this.timeline?.progress(1);

        const prev = this.items[this.items.length - 1];
        const curr = this.items[this.items.length] = [...document.querySelectorAll(`#${target} li > *`)];

        if (this.$desktop) {
            gsap.timeline()
                .to(prev, {
                    y: '-200vh',
                    opacity: 0,
                    duration: 0.3,
                    stagger: 0.1,
                    ease: "back.in(1.7)",
                })
                .to(curr, {
                    y: '-100vh',
                    opacity: 1,
                    duration: 0.2,
                    stagger: 0.1,
                    ease: "back.out(1.7)",
                });
        } else {
            gsap.timeline()
                .to(prev, {
                    x: '-200vw',
                    duration: 0.3,
                    stagger: 0.1,
                    ease: "back.in(1.7)",
                })
                .to(curr, {
                    x: '-100vw',
                    duration: 0.2,
                    stagger: 0.1,
                    ease: "back.out(1.7)",
                })
                .to(this.navigationTarget, {
                    height: document.querySelector(`#${target}`).getBoundingClientRect().height,
                    duration: 0.3,
                }, 0)
                .call(() => this.resizeSlider(0.3), 0)
            ;
        }
    }

    filter (element) {
        if (element instanceof Event) {
            element.preventDefault();
            element = element.params.element;
        }

        const elementId = Number(element);
        this.$filter = this.element.querySelector(`[data-element-id="${elementId}"]`);

        const done = (window.sessionStorage.getItem('counseling-transition') || '').split(',').map(v => Number(v)).filter(v => !!v);
        const transitionType = this.$filter.dataset.transitionType;
        const transitionText = this.$filter.dataset.transitionText;

        if (done.includes(elementId) || !transitionText || !transitionType) {
            this.toFilter();
            return;
        }

        const el = document.createElement('div');
        el.dataset.controller = 'swirlytext';
        el.dataset.swirlytextTextValue = transitionText;
        el.dataset.swirlytextAnimationValue = transitionType;
        document.body.append(el);

        el.addEventListener('swirlytext:full', () => {
            this.toFilter(true);
        });

        done.push(elementId);
        window.sessionStorage.setItem('counseling-transition', done.join(','));
    }

    toFilter (instant = false) {
        this.$filter.style.display = '';

        const y = this.element.children[0].getBoundingClientRect().height * -1;
        const height = this.$filter.getBoundingClientRect().height;

        gsap.timeline({ duration: instant ? 0 : 0.6 })
            .to(this.element, { y, height, ease: 'power2.inOut' }, 0)
            .to(this.illuHome, { opacity: 0 }, 0)
            .to(this.illuFilter, { opacity: 1 }, 0)
        ;
    }

    resizeSlider (duration = 0.6) {
        const height = (this.$filter || this.element.children[0]).getBoundingClientRect().height;

        gsap.timeline()
            .to(this.element, { duration, height, ease: 'power2.inOut' })
        ;
    }
}
